import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import renderAst from '../lib/ast-compiler';
import Seo from '../components/seo';

const GenericPage = ({ data }) => {
  const { markdownRemark } = data;
  const { frontmatter, htmlAst } = markdownRemark;
  const { title } = frontmatter;

  return (
    <Layout>
      <Seo title={title} lang="en" />
      <div className="font-sans">
        <h1 className="mb-8">{title}</h1>
      </div>
      <div className="mb-16">{renderAst(htmlAst)}</div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
      frontmatter {
        title
      }
    }
  }
`;

export default GenericPage; 